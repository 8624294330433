@import "colors.scss";
@import "mixins.scss";

div.contact-form-wrapper {
  margin: 20px 0px;

  input {
    @include border-radius(20px);
    border-color: $TEAL_LIGHT;
  }

  .form-check-input {
    position: static;
    margin: 0px;
    margin-right: 10px;
  }

  textarea {
    @include border-radius(20px);
    resize: none;
  }

  .form-control:valid {
    border-color: $TEAL_LIGHT;
  }

  .form-control:invalid {
    border-color: $GRADIENT_START;
  }

  .invalid-feedback {
    color: $GRADIENT_START;
  }

  a {
    text-decoration: underline;
    color: $DARK_GRAY;

    &:hover {
      color: $NEUTRAL_GRAY;
    }
  }

  input.PhoneInputInput {
    border-color: $LIGHT_GRAY !important;
    border: 1px solid $LIGHT_GRAY;
    height: 38px !important;
    padding-left: 8px;
    @include border-radius(20px);
  }
}
