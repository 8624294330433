@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
@import "colors.scss";
@import "mixins.scss";

$DARK_GRAY: #323643;

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  @include backgroundGradientHorizontal($GRADIENT_START, $GRADIENT_END);
  font-family: "Lato", sans-serif;
  height: 100%;
  overflow-x: hidden;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

#root {
  height: 100%;
  overflow: hidden;
}

.flex-stretching {
  flex: 1 1 auto;
}

.flex-content-shrinking {
  flex: 0 1 content;
}

.alert-text {
  color: $GRADIENT_END;
  background-color: $RED_LIGHT;
  padding: 5px 12px;
  @include border-radius(5px);
}

// remove some unwanted stlyes from pseudo elements

button:focus {
  box-shadow: none !important;
  outline: none !important;
}

textarea:focus,
input:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}
