$GRADIENT_START: #fa8872;
$GRADIENT_END: #f87777;
$RED_LIGHT: #f2d2cc;

$GREEN_ACCENT: #60cda6;
$GREEN_DARK: #6db684;

$TEAL_LIGHT: #e1e9eb;
$TEAL_DARK: #266473;

$LIGHT_GRAY: #edeef0;
$NEUTRAL_GRAY: #a2a8b5;
$DARK_GRAY: #323643;
