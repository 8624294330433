@import "colors.scss";
@import "mixins.scss";

header.navigation-header {
  height: 100%;

  .back-button-holder {
    @include vertical-align-flex();

    button {
      color: #ffffff;
      width: 29px;
      height: 48px;
      background-color: transparent;
      border: none;
      outline: none;

      :active {
        color: $NEUTRAL_GRAY;
      }

      i {
        font-size: 18pt;
        font-weight: 100;
        line-height: 48px;
      }
    }
  }

  h1 {
    text-align: center;
    color: #ffffff;
    padding-top: 42px;
    padding-bottom: 30px;
    margin: 0;
    font-size: 1.8em;
    @media (max-width: 576px) {
      font-size: 1em;
      font-weight: 700;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
