@import "colors.scss";
@import "mixins.scss";

div.bottom-navigation-bar {
  margin: 0 0;
  padding: 10px 0px;

  button.btn {
    transition: all 200ms ease-in-out;
    width: 100%;
    @media (min-width: 576px) {
      width: 98%;
    }
    border: none;
    @include border-radius(50px);
    padding: 10px 0;
    cursor: pointer;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;

    &.back {
      background-color: $NEUTRAL_GRAY;

      &:hover {
        background-color: $DARK_GRAY;
      }
    }

    &.next {
      background-color: $LIGHT_GRAY;
      cursor: default;
      @media (min-width: 576px) {
        margin-left: 2%;
      }

      &:active {
        background-color: $LIGHT_GRAY;
      }

      &.active {
        background-color: $GREEN_ACCENT;
        cursor: pointer;

        &:hover {
          background-color: $GREEN_DARK;
        }
      }
    }
  }
}
