@import "colors.scss";
@import "mixins.scss";

button.address {
  width: 100%;
  border: none;
  @include border-radius(50px);
  padding: 10px 0;
  margin-bottom: 15px;
  cursor: pointer;
  background-color: $GRADIENT_START;
  color: #ffffff;

  &:hover {
    background-color: $GRADIENT_END;
  }

  .click-indicator {
    position: absolute;
    top: 0px;
    right: 140px;
    border: 5px solid #1890ff;
    border-radius: 23px;
    height: 46px;
    width: 46px;
    animation: pulsate 2s ease-out;
    -webkit-animation: pulsate 2s ease-out;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }

  .click-indicator-inner {
    position: absolute;
    top: 13px;
    right: 153px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #1890ff;
    opacity: 0.6;
  }

  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      opacity: 0;
    }
    25% {
      opacity: 0.6;
    }
    50% {
      -webkit-transform: scale(1.2, 1.2);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    20% {
      -webkit-transform: scale(1.2, 1.2);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
