@import "colors.scss";
@import "mixins.scss";

.address-list {
  overflow-y: auto;

  span.no-results {
    color: $NEUTRAL_GRAY;
  }

  .address-cell {
    padding: 0;
    margin: 0;
    border: none;
    @include border-radius(0);
    cursor: pointer;

    p {
      padding: 15px 0;
      margin: 0;
    }

    .separator {
      width: 100%;
      height: 1px;
      background-color: $GRADIENT_START;
    }
  }
}
