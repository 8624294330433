@import "colors.scss";
@import "mixins.scss";

.custom-item-creator {
  input {
    @include border-radius(20px);
    border-color: $TEAL_LIGHT;
  }

  button {
    cursor: pointer;
    @include border-radius(50px);
    border: none !important;
    box-shadow: none !important;
    outline: none !important;

    &.add {
      background-color: $LIGHT_GRAY;
      cursor: default;
      padding: 5px 20px;

      &:active {
        background-color: $LIGHT_GRAY;
      }

      &.active {
        cursor: pointer;
        background-color: $GREEN_ACCENT !important;

        &:hover {
          background-color: $GREEN_DARK !important;
        }
      }
    }

    &.cancel {
      background-color: $NEUTRAL_GRAY;
      padding: 5px 20px;
      margin-right: 10px;

      &:hover {
        background-color: $DARK_GRAY;
      }
    }
  }
}
