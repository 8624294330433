@import "colors.scss";
@import "mixins.scss";

.city-list-wrapper {
  button {
    width: 100%;
    border: none;
    @include border-radius(300px);
    height: 45px;
    margin: 6px 0px;
    cursor: pointer;
    background-color: $GRADIENT_START;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;

    &.other-city {
      background-color: $NEUTRAL_GRAY;
    }

    &:hover {
      background-color: $GRADIENT_END;
    }
  }
}
