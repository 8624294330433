@mixin box-shadow($left, $top, $radius, $color) {
	box-shadow: $left $top $radius $color;
	-webkit-box-shadow: $left $top $radius $color;
	-moz-box-shadow: $left $top $radius $color;
}

@mixin transition($property, $duration, $easing: linear) {
	transition: $property $duration $easing;
	-webkit-transition: $property $duration $easing;
	-moz-transition: $property $duration $easing;
}

@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}

@mixin border-radius-important($radius) {
	border-radius: $radius !important;
	-webkit-border-radius: $radius !important;
	-moz-border-radius: $radius !important;
}

@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
	border-top-left-radius: $topleft;
	border-top-right-radius: $topright;
	border-bottom-right-radius: $bottomright;
	border-bottom-left-radius: $bottomleft;
	-webkit-border-top-left-radius: $topleft;
	-webkit-border-top-right-radius: $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius: $bottomleft;
	-moz-border-radius-topleft: $topleft;
	-moz-border-radius-topright: $topright;
	-moz-border-radius-bottomright: $bottomright;
	-moz-border-radius-bottomleft: $bottomleft;
}

@mixin gradient($color1, $color2) {
	background-color: $color1;
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$color1}, endColorstr=#{$color2});
	background-image: -moz-linear-gradient(center top, $color1, $color2);
	background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($color1), to($color2));
}

@mixin backgroundGradientHorizontal($color1, $color2) {
  background: $color1; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, $color1 , $color2); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, $color1, $color2); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, $color1, $color2); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, $color1, $color2); /* Standard syntax */
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translate(0,-50%);
  -ms-transform: translate(0,-50%);
  transform: translate(0,-50%);
}
@mixin vertical-align-flex() {
  display: flex;
	align-items: center;
}
@mixin horizontal-align($position: relative) {
  position: $position;
  left: 50%;
  -webkit-transform: translate(-50%,0);
  -ms-transform: translate(-50%,0);
  transform: translate(-50%,0);
}
