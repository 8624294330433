@import "colors.scss";
@import "mixins.scss";

div.finish-container {
  .check-badge {
    background-color: white;
    width: 200px;
    height: 200px;
    @include border-radius(100%);
    margin: 20px 0px;
    font-size: 80px;
    text-align: center;
    padding-top: 35px;
  }
  .headline {
    font-size: 1.7em;
    color: #ffffff;
    font-weight: 300;
  }
  .subline {
    color: $DARK_GRAY;
    text-align: center;
  }
  .again-button {
    button {
      background-color: $DARK_GRAY;
      color: #ffffff;
      @include border-radius(20px);
      height: 40px;
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
      cursor: pointer;

      &:hover {
        background-color: $NEUTRAL_GRAY;
      }
    }
  }
  .bottom {
    img {
      width: 100%;
      height: auto;
    }
  }
}
