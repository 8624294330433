@import "colors.scss";
@import "mixins.scss";

.progress-button-holder {
  > div {
    display: inline-block;
  }

  button {
    width: 20px;
    height: 20px;
    @include border-radius(50%);
    background-color: transparent;
    float: left;
    border: 1px solid #fff;
    outline: none;
    display: block;
    padding: 0;
  }

  .separator {
    width: 25px;
    float: left;
    height: 1px;
    background-color: #fff;
    margin-top: 10px;
  }
}
