@import "colors.scss";
@import "mixins.scss";

.floor-elevator {
  color: $DARK_GRAY;
  margin-top: 20px;

  .story-picker {
    border-bottom: 1px solid $TEAL_LIGHT;
    padding-bottom: 36px;

    h5 {
      margin-bottom: 24px;
    }

    button.story {
      width: 40px;
      height: 40px;
      font-weight: 700;
      color: #ffffff;
      font-size: 1.2em;
      @include border-radius(100%);
      border: none;
      cursor: pointer;

      &.minus {
        background-color: $GRADIENT_START;
      }

      &.plus {
        background-color: $GREEN_ACCENT;
      }
    }
  }

  .elevator-picker {
    margin-top: 36px;

    .elevator-label {
      font-size: 0.9em;
      text-align: center;
    }
  }

  .hint-label {
    margin-top: 50px;
    background-color: $TEAL_LIGHT;
    color: $TEAL_DARK;
    font-size: 0.9em;
    text-align: center;
    padding: 20px;
    @include border-radius(12px);
    position: relative;

    .hint-exclamation {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 50%;
      margin-left: -20px;
      top: -20px;
      background-color: $TEAL_DARK;
      @include border-radius(100%);
      svg {
        height: 50%;
        margin-top: 8px;
        width: auto;
      }
    }
  }

  button.finish {
    width: 100%;
    border: none;
    @include border-radius(300px);
    height: 45px;
    margin: 10px 0px;
    cursor: default;
    background-color: $LIGHT_GRAY;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;

    &:active {
      background-color: $LIGHT_GRAY;
    }

    &.active {
      background-color: $GREEN_ACCENT !important;
      cursor: pointer;

      &:hover {
        background-color: $GREEN_DARK !important;
      }
    }
  }
}
