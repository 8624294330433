@import "colors.scss";
@import "mixins.scss";

div {
  .header-wrapper {
    height: 10%;
    @media (min-width: 576px) {
      height: 15%;
    }
  }

  .main-bottom-space {
    display: none;
    height: 0%;
    @media (min-width: 576px) {
      display: block;
      height: 10%;
    }
  }

  .container.master-layout {
    height: 90%;
    @media (min-width: 576px) {
      height: 75%;
    }

    .row {
      div.nav-button-holder {
        button {
          height: 50px;
          width: 50px;
          border: none;
          outline: none;
          @include border-radius(100%);
          background-color: #ffffff;
          opacity: 0.5;
          font-size: 1.5em;
          font-weight: bold;
          color: $GRADIENT_START;
          cursor: pointer;
        }

        button[role="left"] {
          float: right;
          margin-right: 20px;
          margin-left: auto;
          text-align: left;
          padding-left: 15px;
        }

        button[role="right"] {
          float: left;
          margin-right: auto;
          margin-left: 20px;
          text-align: right;
          padding-right: 13px;
        }

        button:hover {
          opacity: 1;
        }
      }
    }
  }
}
