@import "colors.scss";
@import "mixins.scss";

div.overlay-card {
  width: 100%;
  background-color: #ffffff;
  position: relative;
  margin-top: -40px;
  padding-bottom: 16px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.3);
  @include border-radius(15px);
  @media (max-width: 576px) {
    @include border-radii(15px, 15px, 0px, 0px);
  }
}
