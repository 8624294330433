@import "colors.scss";
@import "mixins.scss";

.help-selection {
  margin-top: 10px;
  @media (min-width: 576px) {
    margin-top: 20px;
  }

  .headline {
    text-align: center;
    font-size: 1em;
    color: $DARK_GRAY;
    margin-bottom: 10px;
    @media (min-width: 576px) {
      font-size: 1.2em;
    }
  }

  .helper-cell {
    cursor: pointer;

    .img-wrapper {
      padding: 0;
      text-align: center;
    }

    span.title {
      font-size: 1.1em;
      color: $DARK_GRAY;
    }

    .description {
      font-size: 0.9em;
      line-height: 5px;
      color: $NEUTRAL_GRAY;
    }
  }

  .list-group-item {
    border: none;

    &.active {
      background-color: $TEAL_LIGHT;
      outline: none;
    }

    &:focus {
      outline: none;
    }
  }
}
