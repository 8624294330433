@import "colors.scss";
@import "mixins.scss";

.item-list {
  text-align: center;

  ul.list-group {
    width: 100%;
    border: none;
    text-align: left;

    .list-group-item {
      font-size: 1em;
      padding: 0;
      border: none;
      width: 100%;
      vertical-align: middle;
      @include border-radius(0);

      &:hover {
        background-color: #ffffff;
      }

      .content {
        padding: 15px 0;
        @media (min-width: 576px) {
          padding: 20px 0;
        }
      }

      .separator {
        width: 100%;
        height: 2px;
        background-color: $GRADIENT_START;
      }

      p.item-name {
        margin: 0;
      }

      button.quantity {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        font-weight: bold;
        font-size: 1.4em;
        padding: 0;
        box-shadow: none;
        border: none;
        cursor: pointer;
        margin: 0 10px;
      }

      button.decrease-count {
        background-color: $GRADIENT_START;

        &:hover {
          background-color: $GRADIENT_END;
        }
      }

      button.increase-count {
        background-color: $GREEN_ACCENT;

        &:hover {
          background-color: $GREEN_DARK;
        }
      }
    }

    button.list-group-item {
      cursor: pointer;

      &:hover {
        background-color: $TEAL_LIGHT;
      }
    }
  }
}

.suggestion-fade-enter {
  opacity: 0;
}

.suggestion-fade-enter.suggestion-fade-enter-active {
  opacity: 1;
  transition: all 200ms ease-in-out;
}

.suggestion-fade-exit {
  opacity: 1;
}

.suggestion-fade-exit.suggestion-fade-exit-active {
  opacity: 0;
  transition: all 200ms ease-in-out;
}
