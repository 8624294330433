div.payment-methods-wrapper {
  padding-bottom: 40px;
}

.security {
  width: 130px;
  height: auto;
}

.security-explanation {
  text-align: center;
  font-size: 0.8em;
  margin-top: 24px;
}
