@import "colors.scss";
@import "mixins.scss";

.address-suggestion {
  padding: 12px;
  height: 100%;

  // always show scroll bars
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  // SEARCH FIELD
  input {
    @include border-radius-important(10px);
    background-color: $TEAL_LIGHT;
    color: $TEAL_DARK;
    outline: none;
    border: none;

    &:focus {
      background-color: $TEAL_LIGHT;
    }
  }

  .input-group {
    margin-bottom: 10px;

    button {
      background-color: #ffffff;
      color: $GRADIENT_START;
      border: none;
      cursor: pointer;

      &:hover {
        color: $NEUTRAL_GRAY;
      }

      &:active {
        background-color: #ffffff !important;
        color: $NEUTRAL_GRAY !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }

  p.alert-text {
    margin: 0px;
  }

  .number-alert {
    color: $GRADIENT_END;
    background-color: $RED_LIGHT;
    padding: 5px 12px;
    @include border-radius(5px);
  }
}
