@import "colors.scss";
@import "mixins.scss";

div.time-input-wrapper {
  padding: 24px 0;
  overflow-x: hidden;

  input[type="time"]::-webkit-inner-spin-button,
  input[type="time"]::-webkit-outer-spin-button,
  input[type="time"]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    background: none;
    width: 0px;
  }

  input[type="range"] {
    width: 100%;
    margin: 9.8px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    background: rgba(237, 238, 240, 0.78);
    border: 0px solid rgba(1, 1, 1, 0);
    border: 0;
    border-radius: 25px;
    width: 100%;
    height: 10.4px;
    cursor: pointer;
  }
  input[type="range"]::-webkit-slider-thumb {
    margin-top: -9.8px;
    width: 30px;
    height: 30px;
    background: #fa8872;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 40px;
    cursor: pointer;
    -webkit-appearance: none;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
    -moz-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
    box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ffffff;
  }
  input[type="range"]::-moz-range-track {
    background: rgba(237, 238, 240, 0.78);
    border: 0px solid rgba(1, 1, 1, 0);
    border: 0;
    border-radius: 25px;
    width: 100%;
    height: 10.4px;
    cursor: pointer;
  }
  input[type="range"]::-moz-range-thumb {
    width: 30px;
    height: 30px;
    background: #fa8872;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 40px;
    cursor: pointer;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
    -moz-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
    box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
  }
  input[type="range"]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 9.8px 0;
    color: transparent;
    width: 100%;
    height: 10.4px;
    cursor: pointer;
  }
  input[type="range"]::-ms-fill-lower {
    background: #a5aab4;
    border: 0px solid rgba(1, 1, 1, 0);
    border: 0;
    border-radius: 50px;
  }
  input[type="range"]::-ms-fill-upper {
    background: rgba(237, 238, 240, 0.78);
    border: 0px solid rgba(1, 1, 1, 0);
    border: 0;
    border-radius: 50px;
  }
  input[type="range"]::-ms-thumb {
    width: 30px;
    height: 30px;
    background: #fa8872;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 40px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
    -webkit-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
    -moz-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
    box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: rgba(237, 238, 240, 0.78);
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #ffffff;
  }
}
