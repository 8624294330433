@import "colors.scss";
@import "mixins.scss";

div.loading-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  padding-top: 25%;
  color: #ffffff;
  text-align: center;
  @include border-radius(15px);
}
