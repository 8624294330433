@import "colors.scss";
@import "mixins.scss";

div.card {
  background-color: #ffffff;
  @include border-radius(15px);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  // keeps special child elements (e.g. canvas) from overflowing in webkit
  transform: translate3d(0, 0, 0);
  @media (max-width: 576px) {
    @include border-radii(15px, 15px, 0px, 0px);
  }
}
