@import "colors.scss";
@import "mixins.scss";

div.address-selection-modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  color: #ffffff;
  @include border-radius(15px);

  .content {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @media (min-width: 576px) {
      width: 92%;
      height: 92%;
      margin-top: 4%;
      margin-left: 4%;
    }
    background-color: #ffffff;
    @include border-radius(15px);
  }
}
