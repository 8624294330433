@import "colors.scss";
@import "mixins.scss";

div.address-summary-wrapper {
  div.spacer {
    border-top: 1px solid $GRADIENT_START;
    margin: 18px 0px;
  }

  p.address-title {
    color: $GRADIENT_START;
    font-size: 1em;
    margin-bottom: 4px;
  }

  p.address-summary {
    color: $DARK_GRAY;
    font-size: 1em;
    margin-bottom: 0;
  }

  p.red-text {
    color: $DARK_GRAY;
    font-weight: 300;
    font-style: italic;
    margin-top: 4px;
    margin-bottom: 0;
  }

  button.btn {
    width: 44px;
    height: 44px;
    @include border-radius(100%);
    border: none;
    background-color: $GREEN_ACCENT;
    color: #ffffff;
    padding: 4px 14px;
    cursor: pointer;

    &:hover {
      background-color: $GREEN_DARK;
      border: none;
      color: #ffffff;
    }
  }

  .edit-wrapper {
    text-align: right;
  }
}
