@import "colors.scss";
@import "mixins.scss";

div.DayPickerInput {
  width: 100%;
  input {
    width: 100%;
    color: $DARK_GRAY;
    text-align: center;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border: 1px solid #e1e9eb;
    text-align: center;
    font-size: 2em;
    padding: 6px 12px;
  }

  .DayPickerInput-Overlay {
    width: 100%;

    div.DayPicker {
      color: $DARK_GRAY;
      width: 100%;

      div.DayPicker-wrapper {
        width: 100%;

        div.DayPicker-Month {
          width: 90%;

          div.DayPicker-Caption {
            font-size: 1.2em;
            margin-bottom: 20px;
          }

          div.DayPicker-Weekdays {
            border-bottom: 1px solid $GRADIENT_START;

            div.DayPicker-Weekday {
              font-weight: 700;
              color: $DARK_GRAY;

              abbr {
                text-decoration: none;
              }
            }
          }

          div.DayPicker-Body {
            div.DayPicker-Week {
              div.DayPicker-Day {
                border: none;
                color: $DARK_GRAY;
              }

              div.DayPicker-Day:hover {
                background: radial-gradient(
                  circle at center,
                  $LIGHT_GRAY 0%,
                  $LIGHT_GRAY 49%,
                  #ffffff 49%
                );
              }

              div.DayPicker-Day--disabled {
                color: $NEUTRAL_GRAY;
                background-color: transparent;
                &:hover {
                  background: none;
                }
              }

              div.DayPicker-Day--today {
                color: $DARK_GRAY;
              }

              div.DayPicker-Day--selected {
                color: #ffffff;
                background-color: transparent;
                background-image: url("../../icons/circle_green.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
        }
      }
    }
  }
}
