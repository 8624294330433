@import "colors.scss";
@import "mixins.scss";

div.centered-title {
  color: $DARK_GRAY;
  text-align: center;
  margin: 20px 0px;
  @media (min-width: 576px) {
    font-size: 1.1em;
  }
}
