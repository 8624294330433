.route-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.route-wrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
